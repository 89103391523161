import { StringUtil } from 'Util/Helpers';

const moment = require('moment');

class DateFormatUtil
{
    relativeToNowCal(date) {
        if(StringUtil.isNullOrEmpty(date)) {
            return '';
        }

        return moment(date).calendar(null, {
            sameElse: 'ddd, MMM Do YYYY, h:mm A'
        });
    }

    relativeToNowDateOnly(date) {
        if(StringUtil.isNullOrEmpty(date)) {
            return '';
        }

        return moment(date).calendar(null, {
            sameElse: 'ddd, MMM Do YYYY'
        });
    }

    longDateAbbr(date) {
        if(StringUtil.isNullOrEmpty(date)) {
            return '';
        }
        
        return moment(date).format('ddd, MMM Do YYYY, h:mm A');
    }

    longDateOnly(date) {
        if(StringUtil.isNullOrEmpty(date)) {
            return '';
        }
        
        return moment(date).format('ddd, MMM Do YYYY');
    }

    longDateTime(date) {
        if(StringUtil.isNullOrEmpty(date)) {
            return '';
        }
        
        return moment(date).format('LLL');
    }

    closingDate(date) {
        if(StringUtil.isNullOrEmpty(date)) {
            return '';
        }
        
        return moment(date).format('MMM Do YYYY');
    }
}

const instance = new DateFormatUtil();

export { instance as DateFormatUtil };